import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import './assets/css/reset.css';

const url = "https://api.back.control.paul-cloud.de";

axios.defaults.baseURL = url;
axios.defaults.headers.common['Accept'] = "application/json";
axios.defaults.headers.common['Content-Type'] = "application/json";

library.add(faAngleDown, faArrowLeft);
createApp(App)
    .use(ElementPlus)
    .use(store)
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app');
