<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
</style>

<template>
  <router-view/>
</template>

<script>
export default {
  created () {
    this.$store.dispatch('tryAutoLogin')
  }
}
</script>
<style lang="scss">
  .el-message {
    font-size: 18px !important;
    padding: 15px 80px !important;
    display: flex;
    justify-content: center;
    & * {
      font-size: 18px !important;
    }
  }
  .el-message-box {
    border-radius: 20px !important;
    border-width: 0 !important;
    font-family: "Roboto", sans-serif;
    .el-message-box__header {
      background: #022259;
      color: white;
      padding: 30px;
      .el-message-box__title {
        color: white;
        font-size: 18px;
      }
    }
    .el-message-box__headerbtn {
      display: none;
    }
    .el-message-box__content {
      min-height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
    .el-message-box__btns {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      button {
        border-radius: 15px;
        height: 50px;
        /* padding: 20px 30px; */
        width: 150px;
        background: #022259;
        border: none;
        color: white;
        font-size: 18px;
        &:active,
        &:focus,
        &:hover {
          background: #022d79 !important;
          border: #022d79 !important;
          color: white;
        }
      }
    }
  }
</style>
