import { denormalize, normalize, schema} from 'normalizr';

export const normalizeProjectData = originalData => {
  return normalize(originalData, schemaData);
};

export const denormalizeProjectData = normalizedData => {
  return denormalize(normalizedData.result, schemaData, normalizedData.entities);
};


const sensorPipes = new schema.Entity('pipes', {}, {
  idAttribute: '_id',
});
const sensorCirculation = new schema.Entity('circulation_groups', {
  pipes: [sensorPipes],
}, {
  idAttribute: '_id',
});
const sensorDomestic = new schema.Entity('domestic_water_heating', {
  circulation_groups: [sensorCirculation],
  pipes: [sensorPipes],
}, {
  idAttribute: '_id',
});

const building = new schema.Entity('buildings', {
  domestic_water_heating: [sensorDomestic],
  circulation_groups: [sensorCirculation],
  pipes: [sensorPipes],
}, {
  idAttribute: '_id',
});

const schemaData = new schema.Entity('project', {
  buildings: [building]
}, {
  idAttribute: '_id',
  processStrategy: (obj, parent) => {
    return {...parent, ...obj}
  }
});
