export const getters = {
    getProject(state) {
      return state.localProject;
    },
    getProjectNumber(state) {
      return state.project_number;
    },
    getError(state) {
      return state.error;
    },
    getErrorModbusList(state) {
      return state.errorModbusList;
    },
};
