<template>
    <div class="table-wrap" style="overflow-x:auto;">
        <el-table
                :data="dataTable"
                style="width: 100%; margin-bottom: 20px;"
                :row-key="rowKey"
                default-expand-all
                fit
                header-cell-class-name="el-table__header"
                cell-class-name="el-table__body"
                :row-class-name="rowClassName"
                :tree-props="treeProps"
        >
            <slot />
        </el-table>
    </div>
</template>

<script>

  export default {
    name: 'Table',
    props: {
      dataTable: {
        type: Array,
        default: () => [],
      },
      treeProps: {
        type: Object,
        default: () => ({
          children: '',
          hasChildren: false,
        }),
      },
      rowKey: {
        type: String,
        default: () => 'id',
      },
      rowClassName: {
        type: Function,
        default: null,
      }
    },
    methods: {
      headerCellClassName() {
          return '.el-table__header'
      }
    }
  }

</script>

<style lang="scss">
    .table {
        /*width: 1300px;*/
    }
    .el-table .cell {
        word-break: break-word !important;
    }
    .table-wrap {
        /*height: 73vh;*/
        /*display: grid;*/
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-top: 35px;
    }
    .el-table__header-wrapper {
        table {
            border: 2px solid #EFF9FF;
            .el-table__header {
                font-weight: 500;
                font-size: 18px;
                color: #022259;
                height: 38px;
                padding: 0;
                text-align: center;
                .cell {
                    width: max-content;
                }
            }
        }
    }
    .el-table__body {
        tr {
            height: 60px;
            cursor: pointer;
            td {
                text-align: center;
                padding: 0;
            }
        }
        font-size: 18px;
        font-weight: 400;
        color: #022259;
        width: 100%;
        tr {
            min-height: 60px;
            &:nth-child(odd) { background: #EFF9FF;}
            &.row-level-1 { background: #D3EFFF;}
            &.row-level-2 { background: #EFF9FF;}
            &.row-level-3 { background: #FFFFFF;}
            .table-column {
                text-align: center;
                line-height: 22px;
            }
        }
        tr:hover {
            background: #e2f5ff;
        }
    }
    .el-table__body-wrapper {
        /*max-height: 64vh;*/
        /*max-height: calc(100vh - 320px);*/
        max-height: calc(100vh - 400px);
        overflow: auto !important;
    }
</style>
