import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Auth/Login.vue';
import Header from '../components/Header.vue';
import HeaderExit from '../components/HeaderExit.vue';
import routerWrap from './routerWrap.vue';
import FindProject from '../components/FindProject.vue';
import ProjectDetails from '../views/ProjectDetails.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: 'Login',
    },
    beforeEnter (to, from, next) {
      if (localStorage.getItem('token')) {
        next('/project');
      } else {
        next();
      }
      next('/project');
    }
  },
  {
      path: "/",
      redirect: "/project",
      component: routerWrap,
      children: [
          {
              path: "",
              redirect: "/project",
              component: HeaderExit,
              children: [
                  {
                      path: "/project",
                      name: 'Find the Project',
                      component: FindProject,
                      beforeEnter(to, from, next) {
                          if (localStorage.getItem('token')) {
                              next()
                          } else {
                              next('/login')
                          }
                      }
                },
              ],
              beforeEnter(to, from, next) {
                  if (localStorage.getItem('token')) {
                      next()
                  } else {
                      next('/login')
                  }
              }
          },
          {
              path: "",
              redirect: "/project",
              component: Header,
              children: [
                  {
                      path: "/projectEdit/:id",
                      name: "Details (Edit)",
                      meta: { title: 'Details (Edit)' },
                      component: ProjectDetails,
                      props: { isEdit: true }
                  },
                  {
                      path: "/project/:id",
                      name: "Details",
                      meta: { title: 'Details' },
                      component: ProjectDetails
                  },
              ],
              beforeEnter(to, from, next) {
                  if (localStorage.getItem('token')) {
                      next()
                  } else {
                      next('/login')
                  }
              }
          },
      ],
      beforeEnter(to, from, next) {
          if (localStorage.getItem('token')) {
              next()
          } else {
              next('/login')
          }
      }
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkExactActiveClass: "active",
  routes
});

export default router
