import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state = {
  project: [],
  localProject: [],
  project_number: null,
  errorModbusList: [],
  error: {
    status: false,
    message: '',
  }
};

export const details = {
  state,
  getters,
  actions,
  mutations
};
