<template>
    <div :class="[className, 'input-wrap']">
        <label v-if="label" :for="input_id">{{ label }}</label>
        <div
            :class="['ip-input-container', hasLocalError && 'error', disabled && 'disabled']"
            ref="ipContainer"
        >
            <div class="ip-segment" v-for="(ip, i) in ipValue" :key="`${input_id}_${i}`">
                <input
                    class="ip-segment-input"
                    :id="`${input_id}_${i}`"
                    type="number"
                    :data-part="i"
                    :required="required"
                    :disabled="disabled"
                    v-model="ip.value"
                    maxLength="3"
                    @input="inputValue"
                    @blur="onBlurFunc"
                    @keydown.enter="enterKeyDown"
                />
                <i v-if="i < ipValue.length - 1">.</i>
            </div>
        </div>
        <span v-if="error.status" class="error-mess">{{error.message}}</span>
    </div>
</template>

<script>
  import {defineComponent} from 'vue';

  export default defineComponent({
    props: {
      className: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      value: {
        type: [String, Number],
        default: null
      },
      defaultValue: {
        type: [String, Number],
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disableEnterKey: {
        type: Boolean,
        default: false,
      },
      update: {
        type: Function,
        default: null,
      },
      error: {
        type: Object,
        default: () => ({
          status: false,
          message: ''
        })
      }
    },
    name: 'InputIp',
    data() {
      return {
        input_id: Math.random(),
        hasLocalError: false,
        ipValue: [
          {value: null},
          {value: null},
          {value: null},
          {value: null}
        ],
      }
    },
    mounted() {
      if(!!this.defaultValue && this.value == null) {
        this.update(this.defaultValue);
      } else if(this.value) {
        this.setLocalValue(this.value);
      }
    },
    methods: {
      enterKeyDown(e) {
        if(e.keyCode === 13 && this.disableEnterKey) {
          e.target.blur();
          e.preventDefault();
        }
      },
      inputValue(e) {
        if(e.target.value.length === 3) {
          const { part } = e.target.dataset;
          const nextInput = document.getElementById(`${this.input_id}_${+part+1}`);
          if(nextInput) nextInput.focus();
        }
        if(e.target.value.length > 3) {
          e.preventDefault();
          e.target.value = e.target.value.slice(0, 3);
          if(+e.target.dataset.part === 3) {
            this.ipValue[3].value = this.ipValue[3].value.slice(0, 3)
          }
        } else return e;
      },
      onBlurFunc() {
        let value = this.ipValue.map(ip => ip.value).join('.');
        const sliceLast = () => {
          if(value.slice( -1) === '.') {
            value = value.slice(0, -1);
            sliceLast();
          }
        };
        sliceLast();
        setTimeout(() => {
          if(!this.$refs.ipContainer.contains(document.activeElement)) {
            this.update(value)
          }
        }, 1);
      },
      setLocalValue(val) {
        const parts = val.split('.');
        parts.forEach((part, i) => {
          this.ipValue[i].value = part;
        });
      }
    },
    watch: {
      hasError: function() {
        const isValid = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(this.value ? this.value.trim() : '');
        this.hasLocalError = this.hasError && !isValid;
      },
      value: function (val) {
        this.setLocalValue(val);
        const isValid = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(val.trim());
        if(!isValid) {
          this.hasLocalError = !isValid;
        } else {
          this.hasLocalError = this.hasError && !isValid;
        }
      }
    },
  });
</script>

<style scoped lang="scss">
    .input-wrap {
        position: relative;
        margin: 10px 0;
        max-width: 450px;
        width: 100%;
        label {
            position: absolute;
            font-weight: 500;
            font-size: 18px;
            color: #022259;
            z-index: 5;
            background: linear-gradient(
                            0deg,
                            rgba(255,255,255,0) 0%,
                            rgba(255,255,255,1) 20%,
                            rgba(255,255,255,1) 50%,
                            rgba(255,255,255,1) 80%,
                            rgba(255,255,255,0) 100%
            );
            left: 30px;
            top: -10px;
            padding: 0 3px;
            font-style: normal;
        }
        .ip-input-container {
            display: flex;
            align-items: center;
            padding: 0 20px;
            height: 50px;
            width: 100%;
            font-size: 18px;
            font-weight: 400;
            border: 2px solid #022259;
            box-sizing: border-box;
            border-radius: 13px;
            font-family: 'Roboto', sans-serif;
            color: #022259;
            transition: border .2s;
            &.error {
                border-color: #D51010;
            }
            &.disabled {
                background: rgba(239, 239, 239, 0.3);
            }
            .ip-segment {
                display: flex;
                align-items: inherit;
                height: 100%;
                width: calc(100% / 4);
                input {
                    min-width: 30px;
                    height: inherit;
                    line-height: normal;
                    border: none;
                    outline: none;
                    text-align: center;
                    margin: 0;
                    padding: 0;
                    background-color: transparent;
                    &[type="number"]::-webkit-outer-spin-button,
                    &[type="number"]::-webkit-inner-spin-button {
                        display: none;
                    }
                    &[type="number"] {
                        -moz-appearance:textfield;
                    }
                }
            }
        }
        &.for-table .ip-input-container {
            height: 40px;
        }
        &.size-80 .ip-input-container {
            width: 80px;
            text-align: center;
        }
        &.size-180 .ip-input-container {
            width: 180px;
        }
        .error-mess {
            color: #D51010;
            text-align: center;
            width: 100%;
            display: block;
            margin-top: 10px;
            font-size: 18px;
            position: absolute;
        }
    }
</style>
