
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Admin',
  methods: {
    logout() {
      this.$store.dispatch('logOut').then(() => {
        this.$router.push('/')
      });
    }
  }
});
