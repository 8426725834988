<template>
    <div class="body-container">
        <form class="find-project" @submit.prevent="submit">
            <img src="/images/logo.png" class="logo-image"/>
            <input-c
                type="number"
                :required="true"
                label="Projektnummer"
                :value="number"
                :update="update"
                :hasError="hasError"
                :error="error"
            />
            <button-c
                type="sybmit"
                text="Projekt laden"
                :loading="loading"
                :click="clickSubmit"
            />
        </form>
    </div>
</template>

<script>
  import {defineComponent} from 'vue';
  import { mapGetters, mapActions } from "vuex";
  import Input from '../components/Inputs/InputComponent';
  import Button from '../components/Inputs/ButtonComponent';

  export default defineComponent({
    name: 'FindProject',
    components: {
      'input-c': Input,
      'button-c': Button,
    },
    data() {
      return {
        number: null,
        loading: false,
        hasError: false,
        error: {
          status: false,
          message: '',
        }
      }
    },
    computed: {
      ...mapGetters(['getProjectNumber', 'getError']),
    },
    methods: {
      ...mapActions(['findProject', 'resetError']),
      update(value) {this.number = value},
      clickSubmit(e) {
        const isValid = e.target.form.checkValidity();
        if(!isValid) {
          e.preventDefault();
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      },
      submit() {
        this.loading = true;
        this.error = this.resetError();
        this.findProject(+this.number).then(() => {
          this.loading = false;
          this.error = this.getError;
          const projectNumber = this.getProjectNumber;
          if(!this.error.status && projectNumber) {
            this.$router.push(`/project/${this.number}`)
          }
        });
      }
    }
  });
</script>
<style lang="scss" scoped>
    .body-container {
        font-family: "Roboto", sans-serif;
        width: 100vw;
        height: calc(100% - 100px);
        .find-project {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: inherit;
            .logo-image {
                height: 100px;
                border-radius: 5px;
                margin-bottom: 80px;
            }
            .input-wrap {
                margin-bottom: 50px;
            }
            button {
                width: 200px;
            }
        }
    }
</style>
