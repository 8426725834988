<template>
    <div class="input-row">
        <input
                :id="input_id"
                :disabled="disabled"
                type="radio"
                :value="radio.value"
                :checked="radio.checked"
                @change="update"
                :name="name"
                :required="required"
        />
        <label v-if="radio.label" :class="hasLocalError && 'error'" :for="input_id">{{ radio.label }}</label>
    </div>


</template>

<script>
  import {defineComponent} from 'vue';

  export default defineComponent({
    name: '_RadioBtn',
    props: {
      name: {
        type: String,
        default: ''
      },
      radio: {
        type: Object,
        default: () => (
          {
            label: '',
            checked: false,
            value: null,
            disabled: false,
          }
        )
      },
      hasLocalError: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      update: {
        type: Function,
        default: null,
      }
    },
    data() {
      return {
        input_id: Math.random(),
      }
    },
  });
</script>

<style scoped lang="scss">
    .input-row {
        position: relative;
        margin-bottom: 10px;
        label {
            display: flex;
            align-items: center;
            margin-right: 5px;
            cursor: pointer;
            width: max-content;
            color: #022259;
            &:before {
                content: "";
                display: block;
                background: white;
                border: 2px solid #022259;
                width: 15px;
                height: 15px;
                min-width: 15px;
                min-height: 15px;
                position: relative;
                border-radius: 50%;
                margin-right: 5px;
            }
            &.error:before {
                border-color: #D51010;
            }
        }
        input {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            &:checked + label:after {
                content: "";
                display: block;
                background: #022259;
                width: 9px;
                height: 9px;
                position: absolute;
                border-radius: 50%;
                left: 5px;
            }
            &:disabled + label:before {
                background: transparent;
            }
        }
    }
</style>
