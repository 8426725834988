<template>
    <div class="details-container">
        <table-c :data-table="buildings"
                 :tree-props="{
                     children: 'sensors',
                     hasChildren: 'sensors.length'
                 }"
                 :row-class-name="rowStyle"
                 row-key="_id"
        >
            <el-table-column class-name="first-column-proj-details" width="300" prop="street" label="Gebäude" #default="scope">
                <span v-if="scope.row.street || scope.row.house_number">
                    {{(scope.row.street || '') + ' ' + (scope.row.house_number || '')}}
                </span>
            </el-table-column>
            <el-table-column class-name="tree-column" prop="name" label="Objekt in der App" width="300" #default="scope">
                <div :class="scope.row.type">
                    <span v-if="scope.row.type === 'domestic_water_heating'"><b>{{scope.row.name}}</b></span>
                    <span v-else>{{scope.row.name}}</span>
                    <button v-if="scope.row.sensors" :class="['caret-tree', '']" type="button" @click="showChildren">
                        <font-awesome-icon icon="angle-down" />
                    </button>
                </div>
            </el-table-column>
            <el-table-column prop="final_name" label="Finaler Objektname" #default="scope" width="240">
                <input-c
                    className="for-table"
                    :value="scope.row.final_name"
                    :required="requiredInputs"
                    :disableEnterKey="true"
                    :disabled="readOnly"
                    :hasError="hasError"
                    :update="v => updateInput(v, 'final_name', scope.row)"
                />
            </el-table-column>
            <el-table-column prop="type_sensor" label="Typ" #default="scope" min-width="220">
                <radio-c
                    :radios="getRadios(scope.row)"
                    :name="'type_sensor'+scope.row._id"
                    className="for-table w-100"
                    :required="requiredInputs"
                    :hasError="hasError"
                    :disabled="readOnly"
                    :update="v => updateRadio(v, scope.row)"
                />
            </el-table-column>
            <el-table-column prop="plc_ip" label="PLC IP" #default="scope" min-width="200">
                <input-ip-c
                        className="for-table size-180 flex-left-center"
                        :value="scope.row.plc_ip"
                        :required="requiredInputs"
                        :disableEnterKey="true"
                        :disabled="readOnly"
                        :hasError="hasError"
                        :update="v => updateInput(v, 'plc_ip', scope.row)"
                />
            </el-table-column>
            <el-table-column prop="channel" label="Kanal" min-width="110" #default="scope">
                <input-c
                        className="for-table size-80"
                        :value="scope.row.channel"
                        type="number"
                        :min="1"
                        :max="2"
                        :defaultValue="1"
                        :disableEnterKey="true"
                        :required="requiredInputs"
                        :disabled="readOnly"
                        :hasError="hasError"
                        :update="v => updateInput(v, 'channel', scope.row)"
                />
            </el-table-column>
            <el-table-column prop="modbus" label="Adresse"  min-width="160" #default="scope">
                <input-c
                        className="for-table size-80"
                        :value="scope.row.modbus"
                        :asTypeNumber="true"
                        :disableEnterKey="true"
                        :required="requiredInputs"
                        :disabled="readOnly"
                        :min="scope.row.type === 'domestic_water_heating' && scope.row.type_sensor === 1 ? 0 : 1"
                        :max="scope.row.type === 'domestic_water_heating' && scope.row.type_sensor === 1 ? 1 : 255"
                        :isModbus="true"
                        :maxLength="3"
                        :hasError="hasError"
                        :pattern="ModbusPattern(scope.row)"
                        :update="v => updateInput(v, 'modbus', scope.row)"
                />
            </el-table-column>
        </table-c>
    </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Table from './Table';
  import Input from '../components/Inputs/InputComponent';
  import InputIp from '../components/Inputs/InputIpComponent';
  import Radio from '../components/Inputs/RadioBtnGroup';

  export default {
    name: 'DeviceTable',
    components: {
      'table-c': Table,
      'input-c': Input,
      'input-ip-c': InputIp,
      'radio-c': Radio
    },
    props: {
      buildings: {
        type: Array,
        default: () => []
      },
      reserved_channels: {
        type: Object,
        default: () => ({})
      },
      requiredInputs: {
        type: Boolean,
        default: true
      },
      readOnly: {
        type: Boolean,
        default: true
      },
      hasError: {
        type: Boolean,
        default: false
      },
      updateThen: {
        type: Function,
        default: null
      }
    },
    methods: {
      ...mapActions([
        'updateProjectParams',
        'updateNestedPlcIp'
      ]),
      ModbusPattern(row) {
        const isWarmwas = row.type === 'domestic_water_heating';
        const minValue = isWarmwas && row.type_sensor === 1 ? null : '0\\b';
        if(row && this.reserved_channels[row.plc_ip] && this.reserved_channels[row.plc_ip][row.channel]) {
          const exceptionsArray = this.reserved_channels[row.plc_ip][row.channel];
          const findModbus = exceptionsArray.filter(except => except === row.modbus);
          let exceptionsArrayFiltered = exceptionsArray.filter((item, i) => {
            return exceptionsArray.indexOf(item) === i;
          });
          if(findModbus.length === 1) {
            exceptionsArrayFiltered = exceptionsArrayFiltered.filter(item => item !== row.modbus)
          }
          const exceptions = exceptionsArrayFiltered.map(
            (item, i) => {
              const skipLastItem = i === exceptionsArrayFiltered.length - 1;
              return `${item}\\b${skipLastItem ? '' : '|'}`
            }
          ).join('');
          if(!exceptions.length) return minValue ? `^\\b(?!${minValue})[0-9]*$` : '';
          return `^\\b(?!${minValue ? `${minValue}|` : ''}${exceptions})[0-9]*$`
        } else if (row) {
          return minValue ? `^\\b(?!${minValue})[0-9]*$` : '';
        }
        return null
      },
      showChildren(e) {
        const el = e.target;
        el.closest('tr').querySelector('.el-table__expand-icon').click();
        if(el.classList.contains("shorten")) {
          el.classList.remove("shorten");
        } else {
          el.classList.add("shorten");
        }
      },
      updateRadio(value, row) {
        let name = 'type_sensor';
        if(row.type === "circulation_groups" || row.type === "pipes") {
          name = 'type_valve';
        }
        this.update(name, value, row);
      },
      updateInput(value, name, row) {
        if(name === 'plc_ip') {
          const isValid = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(value.trim());
          this.updateNestedPlcIp({ name, value: value.trim(), row, isValid }).then(this.updateThen);
        }
        else this.update(name, value, row);
      },
      update(name, value, row) {
        this.updateProjectParams({ name, value, row }).then(this.updateThen);
      },
      rowStyle({row}) {
        if (row.type === 'domestic_water_heating') { return 'row-level-1' }
        else if(row.type === 'circulation_groups') { return 'row-level-2' }
        return 'row-level-3'
      },
      getRadios(row) {
        if(row.type === 'domestic_water_heating') {
          return [
            {label: 'Analog', checked: !!(row.type_sensor === 1), value: 1},
            {label: 'Bus', checked: !!(row.type_sensor === 2), value: 2}
          ]
        } else {
          if(!row.type_valve) {
            this.updateRadio(1, row)
          }
          return [
            {label: 'Neu(Vlr)', checked: !!(row.type_valve === 1), value: 1},
            {label: 'Alt(Nrm)', checked: !!(row.type_valve === 2), value: 2}
          ]
        }
      }
    }
  }
</script>

<style lang="scss">
    .caret-tree {
        font-size: 20px;
        color: #022259;
        border: none;
        background: transparent;
        margin-left: 5px;
        & > * {
            transition: all .2s;
            pointer-events: none;
        }
        &.shorten > * {
            transform: rotate(-90deg);
        }
    }
    .el-table__expand-icon {
        pointer-events: none;
        position: absolute !important;
        visibility: hidden;
    }
    .first-column-proj-details {
        text-align: left !important;
        .el-table__indent,
        .el-table__placeholder {
            display: none;
        }
    }
    .tree-column {
        text-align: start !important;
        .domestic_water_heating {
            margin-left: 0;
        }
        .circulation_groups {
            margin-left: 15px;
        }
        .pipes {
            margin-left: 30px;
        }
    }
    .flex-left-center {
        display: flex;
        justify-content: center;
    }
</style>
