import Vuex from "vuex";

import login from "./modules/login";
import { details } from "./modules/details/index";

export default new Vuex.Store({
  modules: {
    login,
    details
  }
});
