<template>
    <!--<el-button :class="className" :disabled="disabled" type="type" :loading="loading" @click="click">-->
        <!--{{loading ? '' : text}}-->
    <!--</el-button>-->
    <button :class="className" :disabled="disabled" :type="type" @click="click" :style="style">
        <slot></slot>
        {{text}}
    </button>
</template>

<script>
  import {defineComponent} from 'vue';

  export default defineComponent({
    name: 'Button',
    props: {
      text: {
        type: String,
        default: () => ''
      },
      element: {
        type: Element,
        default: null
      },
      style: {
        type: String,
        default: () => ''
      },
      disabled: {
        type: Boolean,
        default: () => false
      },
      loading: {
        type: Boolean,
        default: () => true
      },
      type: {
        type: String,
        default: () => 'button'
      },
      className: {
        type: String,
        default: () => ''
      },
      click: {
        type: Function,
      }
    },
  });
</script>

<style scoped lang="scss">
    button {
        padding: 10px 35px;
        background: #022259;
        border-radius: 22px;
        border: none;
        color: white;
        font-size: 18px;
        height: 50px;
        &:active,
        &:focus,
        &:hover {
            background: #022d79;
            border: #022d79;
            color: white;
        }
        &.as-text {
            height: 26px;
            padding: 5px;
            width: fit-content;
            align-self: end;
            background: transparent;
            color: #0b2259;
            font-size: 18px;
            &:active,
            &:focus,
            &:hover {
                color: #022d79;
            }
        }
        &.for-table {
            height: 40px;
        }
        &:disabled {
            background: #c5c5c5;
        }
    }

</style>
