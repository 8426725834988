<template>
  <form id="projectDetails">
      <div class="header-row">
          <router-link class="backspace" to="/project"><font-awesome-icon icon="arrow-left" />Projekt wechseln</router-link>
          <button-c class-name="as-text"
                    type="button"
                    :click="clickDeactivate"
          >
              <img src="../assets/icons/cross.png"/>
              Projekt löschen
          </button-c>
      </div>
    <div class="project-details-header">
      <div class="header-text">
        <h1>{{project.number}}</h1>
        <div>
          <span><b>Vertriebler:</b>{{project.sales_representative}}</span>
          <span><b>Projektname (aus App):</b>{{project.name}}</span>
        </div>
        <span v-if="project.status === 1" class="status">Aktiv</span>
      </div>
      <div class="header-inputs">
          <div class="address-box">
              <div class="address-content">
                  <div class="inputs-box">
                      <input-c
                              v-for="(id, i) in addressIds"
                              :key="id"
                              className="for-table size-400 fs-14"
                              label="Straße Hausnummer"
                              :value="project[`address_line_${id}`] || ''"
                              :disableEnterKey="true"
                              :hasError="hasError"
                              :disabled="readOnly"
                              :removeBtn="i !== 0"
                              :removeBtnClick="() => removeAddressClick(id)"
                              :update="val => update(`address_line_${id}`, val)"
                      ></input-c>
                  </div>
                  <button-c
                          v-if="addressIds.length < maxCountAddress"
                          class="as-text for-table"
                          type="button"
                          :disabled="readOnly"
                          :click="addAddressClick">
                      <img src="../assets/icons/add.png"/>
                  </button-c>
              </div>
              <div class="address-content">
                  <input-c
                          className="for-table size-130 m-0 mr-10 fs-14"
                          label="Postleitzahl"
                          :value="project.zipcode"
                          :disableEnterKey="true"
                          :hasError="hasError"
                          :disabled="readOnly"
                          :update="val => update('zipcode', val)"
                  ></input-c>
                  <input-c
                          className="for-table size-200 m-0 fs-14"
                          label="Stadt"
                          :value="project.city"
                          :disableEnterKey="true"
                          :hasError="hasError"
                          :disabled="readOnly"
                          :update="val => update('city', val)"
                  ></input-c>
              </div>
          </div>
          <radio-c
                  v-if="isEdit"
                  label="Read only"
                  style="margin: 15px 20px; width: auto;"
                  :radios="[
                  {label: 'on', checked: readOnly, value: 1},
                  {label: 'off', checked: !readOnly, value: 2}]"
                  name="projectStatus"
                  :update="radioUpdate"
          ></radio-c>
          <button-c class-name="for-table"
                text="Aktivieren"
                type="submit"
                :click="clickSubmit"
                :disabled="readOnly"
          ></button-c>
        <span v-if="hasError" class="error-mess">Bitte füllen Sie alle Felder aus.</span>
        <span
            v-else-if="!hasError && getErrorModbusList.length"
            class="error-mess"
        >Adresse doppelt vergeben.</span>
      </div>
    </div>
    <details-table
        :hasError="hasError"
        :buildings="project.sensors"
        :requiredInputs="requiredInputs"
        :readOnly="readOnly"
        :updateThen="updateThen"
        :reserved_channels="reserved_channels"
    ></details-table>
  </form>
</template>

<script>
import {defineComponent} from 'vue';
import { mapGetters, mapActions } from "vuex";
import DetailsTable from '../components/DetailsTable.vue';
import Button from '../components/Inputs/ButtonComponent';
import Input from '../components/Inputs/InputComponent';
// import InputGroup from '../components/Inputs/InputGroupComponent';
import Radio from '../components/Inputs/RadioBtnGroup';

export default defineComponent({
  name: 'ProjectDetails',
  components: {
    'details-table': DetailsTable,
    'button-c': Button,
    'input-c': Input,
    // 'input-group-c': InputGroup
    'radio-c': Radio
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      project: {},
      requiredInputs: true,
      readOnly: true,
      hasError: false,
      reserved_channels: {},
      addressIds: [1],
      maxCountAddress: 3,
    }
  },

  computed: {
    ...mapGetters(['getProject', 'getErrorModbusList']),
  },
  created() {
    this.getProjectFunc();
  },
  methods: {
    ...mapActions([
      'getProjectAction',
      'updateProjectParams',
      'setProjectParams',
      'deactivatingProject'
    ]),
    normalizeTableData(project) {
      const updProject = project.entities.project[project.result];
      const reserved = {};
      this.reserved_channels = {};

      const _setReservedChannels = (obj) => {
        if(obj.plc_ip) {
          reserved[obj.plc_ip] = {...reserved[obj.plc_ip] || {}};
          if(obj.channel && obj.modbus) {
              reserved[obj.plc_ip][obj.channel] =
                (reserved[obj.plc_ip][obj.channel] || []).concat(obj.modbus)
          }
        }
      };
      const _updSensorFunc = (obj, type, building_id, entities, isFirst = false) => {
        if(!obj[type]) return [];
        if(isFirst) _setReservedChannels(obj);
        return obj[type].map((child_id, i) => {
          const child = entities[type][child_id];
          const prevBuildingId = i !== 0 ? entities[type][obj[type][i-1]].building : null;
          const updObj = {...child, type, building_id};
          if(isFirst && i === 0) {
            updObj.street = obj.street;
            updObj.house_number = obj.house_number;
          } else if(!isFirst && (child.building !== building_id || child.building !== prevBuildingId && prevBuildingId !== null)) {
            const prevChild = i === 0 ? null : entities[type][obj[type][i-1]];
            if(i === 0 || (i !== 0 && ((prevChild.building === building_id) || (prevChild.building !== child.building)))) {
              updObj.street = entities.buildings[child.building].street;
              updObj.house_number = entities.buildings[child.building].house_number;
            }
          }
          _setReservedChannels(updObj);

          if(child.circulation_groups) {
            updObj.sensors = _updSensorFunc(child, 'circulation_groups', building_id, entities)
          } else if(child.pipes) {
            updObj.sensors = _updSensorFunc(child, 'pipes', building_id, entities)
          }
          return updObj;
        });
      };

      const data = {
        ...updProject,
        sensors: [
          ...updProject.buildings.map(id => {
            const building = project.entities.buildings[id];
            return [
              ..._updSensorFunc(building, 'domestic_water_heating', building._id, project.entities, true),
              ..._updSensorFunc(building, 'circulation_groups', building._id, project.entities, true),
              ..._updSensorFunc(building, 'pipes', building._id, project.entities, true),
            ];
          }).flat(),
        ]
      };
      delete data.buildings;
      return {data, reserved};
    },
    getProjectFunc() {
      const projectNumber = this.getProjectNumber;
      if(projectNumber) {
        this.updateThen(true)
      } else {
        const { id } = this.$router.currentRoute.value.params;
        this.getProjectAction(id).then(() => this.updateThen(true)).then(() => {
          this.readOnly = this.project.status === 1;
        })
      }
    },
    radioUpdate(value) {
      this.hasError = false;
      this.readOnly = value === 1;
    },
    nameUpdate(value) {
      this.update('final_name', value);
    },
    update(name, value) {
      this.updateProjectParams({ name, value }).then(this.updateThen);
    },
    updateThen(isInit = false) {
      const normalize = this.normalizeTableData(this.getProject);
      this.project = normalize.data;
      this.reserved_channels = normalize.reserved;
      let i = 0;
      const newAddressIds = this.addressIds;
      do {
        i++;
        if (this.project[`address_line_${i}`] !== undefined
          && !newAddressIds.some(id => id === i)
        ) newAddressIds.push(i)
      } while (i < this.maxCountAddress);
      this.addressIds = newAddressIds;
      if (!isInit) this.submit(true);
    },
    clickSubmit(e) {
      const isValid = e.target.form.checkValidity();
      if(!isValid) {
        e.preventDefault();
        this.hasError = true;
        // this.$message.error('Nicht alle erforderlichen Felder sind ausgefüllt');
      } else {
        e.preventDefault();
        this.hasError = false;
        this.$confirm('Nach dem Speichern ist die Bearbeitung nicht mehr verfügbar', 'Möchten Sie die Änderungen speichern?', {
          confirmButtonText: 'Ok',
          cancelButtonText: 'Abbrechen',
          center: true
        }).then(() => {
          this.update('status', 1);
          this.radioUpdate(1);
          this.submit();
        }).catch(() => {
          e.preventDefault();
        });
      }
    },
    submit(isUpdate) {
      this.setProjectParams(this.getProject).then(error => {
        if(error && error.status) {
          // this.$message.error(error.message);
        } else {
          if(!isUpdate)  this.readOnly = this.project.status === 1;
          // this.$message({
          //   message: 'Gespeichert',
          //   type: 'success'
          // });
          // this.$router.push('/project');
        }
      });
    },
    clickDeactivate() {
      this.$confirm('Wollen Sie dieses Projekt wirklich löschen?', 'Sie verlieren alle Eingaben.', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        center: true
      }).then(() => {
        this.deactivatingProject({id: this.project._id}).then(() => {
          this.$router.push('/project');
        });
      }).catch();
    },
    addAddressClick() {
      let i = 0;
      let added = false;
      do {
        i++;
        if(!this.addressIds.some(id => id === i)) {
          added = true;
          this.addressIds.push(i);
        }
      } while (i < this.maxCountAddress && !added)
    },
    removeAddressClick(i) {
      this.addressIds = this.addressIds.filter(id => id !== i);
      if(this.project[`address_line_${i}`])
        this.update(`address_line_${i}`, undefined);
      if(!this.addressIds.length) {
        this.addressIds.push(1);
      }
    }
  }
});
</script>
<style scoped lang="scss">
  #projectDetails {
    margin: 20px 40px 40px;
    .header-row {
        display: flex;
        justify-content: space-between;
        button.as-text img {
            width: 12px;
            margin-top: -2px;
        }
     }
    .backspace {
      color: #022259;
      text-decoration: none;
      width: max-content;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 5px;
      display: block;
      svg {
        margin-right: 5px;
      }
      &:active,
      &:focus,
      &:hover {
        color: #022d79;
      }
    }
    .project-details-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            &.header-text {
             color: #022259;
             h1 {
               font-size: 36px;
             }
             div {
                display: flex;
               flex-direction: column;
               span {
                 width: max-content;
                 margin: 3px 10px;
                 font-size: 18px;
                 max-width: 35vw;
                 b {
                   margin-right: 5px;
                 }
               }
             }
             .status {
               background: #E4F5FF;
               border-radius: 20px;
               padding: 5px 20px;
               margin: 0 10px;
             }
            }
            &.header-inputs {
             width: 100%;
             justify-content: flex-end;
             position: relative;
             align-items: flex-start;
                 & .input-wrap {
                   min-width: 340px;
                   margin: 10px 0;
                   &.size-130 {
                       min-width: 130px;
                       width: auto;
                   }
                   &.size-200 {
                       min-width: 200px;
                       width: auto;
                   }
                 }
                 & .m-0 {margin: 0}
                 & .mr-10 {margin-right: 10px}
                 & .mt-15 {margin-top: 15px}
                 .error-mess {
                   position: absolute;
                   top: 60px;
                   color: #d51010;
                 }
                & > button {
                    margin: 15px 0;
                }
            }
        }
    }
    .address-box {
        width: 100%;
        max-width: 380px;
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        flex-direction: column;
        margin: 0 20px;
        & .inputs-box > * {
            margin: 10px 0 20px;
        }
        & > .address-content {
            display: flex;
            > button {
                align-self: center;
                padding: 0 !important;
                width: 30px;
                height: 30px;
                margin: auto 10px 15px 5px;
            }
        }
    }
 }
</style>
