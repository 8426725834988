<template>
    <div class="radio-wrap" :style="style">
        <label v-if="label">{{ label }}</label>
        <div :class="[className || 'row', 'radio-group']">
            <radio-c
                v-for="(radio, index) in radios"
                :key="index"
                :radio="radio"
                :name='name'
                :disabled="disabled"
                :required="required"
                :update="localUpdate"
                :hasLocalError="hasLocalError"
            ></radio-c>
        </div>
    </div>
</template>

<script>
  import {defineComponent} from 'vue';
  import RadioBtn from './_RadioBtn';

  export default defineComponent({
    name: 'RadioBtnGroup',
    props: {
      className: {
        type: String,
        default: () => 'row'
      },
      label: {
        type: String,
        default: ''
      },
      style: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: () => false,
      },
      required: {
        type: Boolean,
        default: () => false,
      },
      radios: {
        type: Array,
        default: () => ([
          {
            label: '',
            checked: false,
            value: null,
            disabled: false,
          }
        ])
      },
      name: {
        type: String,
        default: () => Math.random(),
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      update: {
        type: Function,
        default: null,
      }
    },
    data() {
      return {
        input_id: Math.random(),
        hasLocalError: false,
      }
    },
    components: {
      'radio-c': RadioBtn
    },
    methods: {
      localUpdate(e) {
        this.hasLocalError = false;
        this.update(+e.target.value);
      }
    },
    watch: {
      hasError: function() {
        this.hasLocalError = this.hasError && this.required && !this.radios.filter(radio => radio.checked).length;
      },
    },
  });
</script>

<style scoped lang="scss">
    .radio-wrap {
        label {
            font-weight: 500;
            display: block;
            font-size: 14px !important;
            margin-bottom: 10px !important;
            color: #022259;
        }
        .radio-group {
            display: flex;
            &.row {
                flex-direction: row;
            }
            &.column {
                flex-direction: column;
            }
            &.for-table {
                justify-content: center;
                & > * {
                    width: 50%;
                    margin-bottom: 0;
                }
                &.w-100 > * {
                    width: 100px;
                }
            }
        }
    }
</style>
