import {denormalizeProjectData, normalizeProjectData} from "./normalize";

export const mutations = {
    findProject(state, payload) {
      state.error = { status: false, message: '' };
      state.project_number = payload.number;
      const data = normalizeProjectData(payload);
      state.project = data;
      state.localProject = data;
    },
    findProjectError(state) {
      state.error = _error(true, 'Diese Projektnummer existiert nicht oder ist noch nicht vollständig erfasst.');
      state.project_number = null;
    },
    setError(state, payload) {
      state.error = payload;
    },
    resetError(state) {
      state.error = _error(false, '');
    },
    setErrorModbusList(state, payload) {
      state.errorModbusList = payload;
    },
    getProjectAction(state, payload) {
      const data = normalizeProjectData(payload);
      state.project = data;
      state.localProject = data;
    },
    setProjectParams(state) {
      state.project = denormalizeProjectData(state.localProject);
    },
    updateProjectParams(state, payload) {
      if(payload.row) {
        state.localProject.entities[payload.row.type][payload.row._id][payload.name] = payload.value;
      } else {
        const project = state.localProject.entities.project[state.localProject.result];
        project[payload.name] = payload.value;
      }
    },
    updateNestedPlcIp(state, payload) {
      state.localProject.entities[payload.row.type][payload.row._id][payload.name] = payload.value;
      if(payload.isValid) {
        _ifForChildrenFunc(state.localProject.entities[payload.row.type][payload.row._id], payload, state.localProject.entities);
      }
    }
};

const _ifForChildrenFunc = (obj, payload, state) => {
    obj[payload.name] = obj[payload.name] ? obj[payload.name] : payload.value;
    if(obj.circulation_groups || obj.pipes) {
      const children = obj.circulation_groups ? 'circulation_groups' : 'pipes';
      obj[children].forEach(id => {
        _ifForChildrenFunc(state[children][id], payload, state);
      })
    }
};

const _error = (status, message) => ({status, message});
