<template>
    <div :class="[className, 'input-wrap']">
        <label v-if="label" :for="input_id">{{ label }}</label>
        <button v-if="removeBtn" class="remove-btn" @click="removeBtnClick" :disabled="disabled">
            <img src="../../assets/icons/cross.png"/>
        </button>
        <input :class="['form-control', hasLocalError && 'error']"
               :id="input_id"
               :disabled="disabled"
               :type="type"
               :value="value"
               :defaultValue="defaultValue"
               :required="required"
               :min="min"
               :max="max"
               :max-length="maxLength"
               :pattern="pattern"
               ref="input"
               @input="inputValue"
               @change="localUpdate"
               @keydown.enter="enterKeyDown"
        />
        <span v-if="error.status" class="error-mess">{{error.message}}</span>
    </div>
</template>

<script>
  import {defineComponent} from 'vue';
  import { mapGetters, mapActions } from "vuex";

  export default defineComponent({
    props: {
      className: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      value: {
        type: [String, Number],
        default: null
      },
      defaultValue: {
        type: [String, Number],
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: 'text'
      },
      pattern: {
        type: String,
        default: null
      },
      isModbus: {
        type: Boolean,
        default: false
      },
      asTypeNumber: {
        type: Boolean,
        default: false
      },
      min: {
        type: Number,
        default: null,
      },
      max: {
        type: Number,
        default: null,
      },
      maxLength: {
        type: Number,
        default: null,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disableEnterKey: {
        type: Boolean,
        default: false,
      },
      removeBtn: {
        type: Boolean,
        default: false,
      },
      removeBtnClick: {
        type: Function,
        default: null,
      },
      update: {
        type: Function,
        default: null,
      },
      error: {
        type: Object,
        default: () => ({
          status: false,
          message: ''
        })
      }
    },
    name: 'Input',
    data() {
      return {
        input_id: Math.random(),
        hasLocalError: false,
      }
    },
    mounted() {
      if(!!this.defaultValue && this.value == null) {
        this.update(this.defaultValue);
      }
    },
    computed: {
      ...mapGetters(['getErrorModbusList']),
    },
    methods: {
      ...mapActions(['setErrorModbusList']),
      enterKeyDown(e) {
        if(e.keyCode === 13 && this.disableEnterKey) {
          e.target.blur();
          e.preventDefault();
        }
      },
      localUpdate(e) {
        let { value } = e.target;
        if(this.type === 'number' || this.asTypeNumber) {
          e.target.value =
            this.max && value > this.max
              ? this.max
              : (this.min === 0 && +value === this.min ? 0 : +value || '');
        }
        this.update(e.target.value)
      },
      inputValue(e) {
        if(this.asTypeNumber) {
          if (isNaN(e.target.value.slice(-1)[0])) e.target.value = e.target.value.slice(0, -1);
          e.preventDefault();
        }
        if(this.maxLength && e.target.value.length > this.maxLength) {
          e.target.value = e.target.value.slice(0, -1);
          e.preventDefault();
        } else return e;
      },
      modbusErrorFunc() {
        if(this.isModbus) {
          if(this.hasLocalError && !this.getErrorModbusList.find(item => item === this.input_id)) {
            this.setErrorModbusList(
              this.getErrorModbusList.concat(this.input_id));
          } else if(!this.hasLocalError) {
            this.setErrorModbusList(
              this.getErrorModbusList
                .filter(item => (item !== this.input_id)))
          }
        }
      }
    },
    watch: {
      hasError: function() {
        this.hasLocalError = this.hasError && !this.$refs.input.validity.valid;
      },
      value: function (val) {
        this.$refs.input.value = val;
        if(this.pattern) {
          this.hasLocalError = !this.$refs.input.validity.valid;
        } else {
          this.hasLocalError = this.hasError && !this.$refs.input.validity.valid;
        }
        this.modbusErrorFunc();
      },
      pattern: function (val) {
        if(this.$refs.input.value) {
          this.hasLocalError = !val ? false : !new RegExp(val).test(this.$refs.input.value);
          this.modbusErrorFunc();
        }
      }
    },
    // computed: {
      // localValue: {
      //   get() {
      //     return this.value;
      //   },
      //   set(value) {
      //     debugger;
      //     if(this.type === 'number' && this.max && value > this.max) {
      //       value = this.max;
      //     }
      //     this.$data.hasLocalError =  this.$props.hasError && !this.$refs.input.validity.valid;
      //     return value
      //     // this.update({value, type: this.type});
      //   }
      // }
    // }
  });
</script>

<style scoped lang="scss">
    .input-wrap {
        position: relative;
        margin: 10px 0;
        max-width: 450px;
        width: 100%;
        label {
            position: absolute;
            font-weight: 500;
            font-size: 18px;
            color: #022259;
            z-index: 5;
            background: linear-gradient(
                0deg,
                rgba(255,255,255,0) 0%,
                rgba(255,255,255,1) 20%,
                rgba(255,255,255,1) 50%,
                rgba(255,255,255,1) 80%,
                rgba(255,255,255,0) 100%
            );
            left: 30px;
            top: -10px;
            padding: 0 3px;
            font-style: normal;
        }
        .remove-btn {
            position: absolute;
            padding: 5px 10px;
            right: 10px;
            top: -15px;
            background: linear-gradient(
                            0deg,
                            rgba(255,255,255,0) 0%,
                            rgba(255,255,255,1) 20%,
                            rgba(255,255,255,1) 50%,
                            rgba(255,255,255,1) 80%,
                            rgba(255,255,255,0) 100%
            );
            border: none;
            color: #022259;
            transition: all .2s;
            z-index: 1;
            img {
                width: 16px;
            }
            &:hover img {
                filter: hue-rotate(125deg);
                color: #D51010;
            }
            &:disabled {
                pointer-events: none;
            }
        }
        input {
            padding: 0 20px;
            height: 50px;
            width: 100%;
            font-size: 18px;
            font-weight: 400;
            border: 2px solid #022259;
            box-sizing: border-box;
            border-radius: 13px;
            font-family: 'Roboto', sans-serif;
            color: #022259;
            transition: border .2s;
            &[type="number"]::-webkit-outer-spin-button,
            &[type="number"]::-webkit-inner-spin-button {
                display: none;
            }
            &[type="number"] {
                -moz-appearance:textfield;
            }
            &.error {
                border-color: #D51010;
            }
            &:disabled {
                background: rgba(239, 239, 239, 0.3);
            }
        }

        &.for-table .form-control {
            height: 40px;
        }
        &.size-80 input {
            width: 80px;
            text-align: center;
        }
        &.size-130 input {
            width: 130px;
        }
        &.size-180 input {
            width: 180px;
        }
        &.size-200 input {
            width: 200px;
        }
        &.fs-14 {
            label, input {
                font-size: 14px !important;
                top: -8px;
            }
            .remove-btn {
                padding: 3px 10px;
                top: -12px;
                img {
                    width: 12px;
                }
            }
        }
        .error-mess {
            color: #D51010;
            text-align: center;
            width: 100%;
            display: block;
            margin-top: 10px;
            font-size: 18px;
            position: absolute;
        }
    }
</style>
