import axios from "axios";
import {denormalizeProjectData} from "./normalize";

const axiosGet = method => {
  return axios.get(method, {
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
    validateStatus: false
  })
};
const axiosPost = (method, payload) => {
  return axios.post(method, payload, { headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}})
};
const axiosDelete = (method) => {
  return axios.delete(method, { headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}})
};

export const actions = {
  async findProject({ commit }, payload) {
    const response = await axiosGet("/projects/" + payload);
    if(response.status === 200) {
      commit("findProject", response.data);
    } else {
      commit("findProjectError", response.data);
    }
  },
  async getProjectAction({ commit }, payload) {
    const response = await axiosGet("/projects/" + payload);
    if(response.status === 200) {
      commit("getProjectAction", response.data);
    }
    else {
      console.error('error getProjectAction', response);
      // commit("getProjectActionError", response.data);
      // this.$router.push('/project');
    }
  },
  async setProjectParams({ commit }, payload) {
    const response = await axiosPost("/projects", denormalizeProjectData(payload));
    if(response.status === 204) {
      commit("setProjectParams");
    } else {
      return {status: true, message: 'error in saving project details'};
    }
  },
  async deactivatingProject({ commit }, payload) {
    const response = await axiosDelete('projects/' + payload.id);
    if(response.status === 204) {
      return true;
    } else {
      return {status: true, message: 'error in deactivating project details'};
    }
  },
  updateProjectParams({ commit }, payload) {
    commit("updateProjectParams", payload);
  },
  updateNestedPlcIp({ commit }, payload) {
    commit("updateNestedPlcIp", payload);
  },
  setError({ commit }, payload) {
    commit("setError", payload);
  },
  resetError({ commit }, payload) {
    commit("resetError", payload);
  },
  setErrorModbusList({ commit }, payload) {
    commit("setErrorModbusList", payload);
  },
};
